.notifications {
	.main-title {
		font-weight: 700;
		font-size: 64px;
	}
	.tab-content {
		.tab-pane {
			background: linear-gradient(180deg, #90DAC4 0%, rgba(217, 217, 217, 0) 30%);
			min-height: 1000px;
			.cal-nav-btn {					
				font-size: 2em;
				text-align: center;
				svg {
					cursor: pointer;
				}
				&.disabled {
					cursor: default;
					pointer-events: none;
					color: grey;
				}			
			}			
			.week {
				text-align: center;
				padding-top: 100px;	
				.day-row {
					margin: 40px 0;
				}				
				.day {	
					margin: 30px 10px;
					.day-name {
						text-align: left;
						font-size: 20px;
					}
					.day-card {						
						width: 100%;						
						color: black;
						border-radius: 10px;
						.card-body {
							display: grid;
							grid-template-columns: 1fr 4fr;
							.dom {
								line-height: 115px;
								font-size: 40px;								
							}
							.event-holder {								
							    height: 100%;
								padding: 10px;
								margin: 0;
								display: flex;
								align-items: center;
								justify-content: center;	               
				               	.btn {	
									width: fit-content;									
									.badge {
										position: relative;
	  									top: -6px;
										border-radius: 50%;
									}	
								}											                
							}
						}						
					}									
				}
				.help-vids {					
					.help-vid {
						margin-top: 100px;
					}
				}
			}
			.month {
				padding-top: 100px;				
				.react-calendar {
					.react-calendar__month-view__weekdays {
						display: none;
					}
					.react-calendar__month-view__weekdays {
						margin-left: 10px;
						text-align: center;
						.react-calendar__month-view__weekdays__weekday {					
							abbr {
								cursor:auto;
								text-decoration: none;
							}
						}	
					}					
					.react-calendar__month-view__days {						
						.react-calendar__tile {		
							pointer-events: none;
							cursor: default;
							background-color: unset;
							border: none;								
							padding: 10px;	
							.tile {
								background: blue;
								display: grid;								
								grid-template-rows: 1fr 2fr;
								border-radius: 10px;								
								.tile-date {									
									font-weight: 700;
									font-size: 2vw;			
									color: #45454B;										
								}								
								&.has-events {
									cursor: pointer;
									pointer-events: auto;
								}
							}
							abbr {
								display: none;
							}
						}
						.tile-events {
							font-size: 3vw;	
							.badge {
								font-size: 1.6vw;
								border-radius: 50%;
							}								
						}		
					}					
				}
			}	
			.month-name {
				border-top: 2px solid white;
				font-weight: 700;
				font-size: 56px;				
				background: linear-gradient(89.62deg, #90DAC4 1.53%, #9A77F8 94.59%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
				padding-top: 20px;
				text-align: center;
			}		
		}
	}	
	.nav-tabs {
		border-bottom: none;
		border-radius: 6px;		
		margin: 0 50px;
		.nav-item {
			margin: 0 40px 40px 40px;
			.nav-link {		
				font-weight: 700;
				font-size: 36px;
				color: white;
				background: rgba(144, 218, 196, 0.5);
				border: none;
				&.active {
					background: #90DAC4;
					position: relative;
					top: 41px;
				}
			}	
		}
	}	
}