.simulator {
	.banner-row {
		margin-top: 90px;		
	}
	.main-title {
		font-weight: 700;
		font-size: 64px;
		line-height: 125%;
	}
	.sub-title {
		font-weight: 700;
		font-size: 40px;
		line-height: 125%;	
		color: white;
		margin-bottom: 40px;
		a {
			color: white;
		}
	}
	.sub-description {		
		font-weight: 700;
		font-size: 24px;
		line-height: 125%;		
		color: #9A77F8;
		max-width: 600px;
	}	
	.mock-form-title {
		font-weight: 700;
		font-size: 48px;
		text-align: center;		
		margin-bottom: 30px;
	}
	.decorator {
		font-weight: 700;
		font-size: 150px;
		line-height: 180px;
		color: rgba(255, 255, 255, 0.2);
	}	
	.mock-form {			
		background: linear-gradient(180deg, #AFDBF3 0%, #9747FF 100%);
		border-radius: 10px;
		padding: 28px 0;			
		.row {	
			margin: 15px 0;		
			.mock-form-item {
				.btn-holder {
					display: flex;
	                flex-direction: column;
	                align-items: center;
					.anchor-btn {						
						cursor: pointer;
						text-decoration: underline;
					}	
				}
				.col-form-label {
					font-weight: 700;
					font-size: 36px;
					line-height: 32px;
					text-align: right;
					color: #45454B;

				}	
				.paste-icon {
					cursor: pointer;
					font-size: 26px;								
					&.disabled {
						pointer-events: none;
						cursor: default;
						color: grey;
					}
					img {
						width: 22px;
					}
				}	
			}
		}
		.sim-select .muvr-select__control {         
		    height: 42px;
		}
	}
	.suggested-form {
		border-radius: 10px;
		padding: 0;		
		background-color: #1C1C25;	
		padding-bottom: 69px;
		.row {	
			margin: 0;		
			.suggested-form-item {	
				padding: 0;
				margin-top: 60px;
				pointer-events: none;		
				.col-form-label {
					font-weight: 700;
					font-size: 36px;
					line-height: 32px;
					text-align: right;
					color: #45454B;
				}
				.copy-icon,.remove-icon {
					pointer-events: auto;
					cursor: pointer;
					font-size: 26px;
					position: relative;
					top: -5px;
					&.copy-icon {
						&.copied {
							
						}
						&.disabled {
							pointer-events: none;
							cursor: default;							
						}
						img {
							width: 22px;
						}
					}
				}		
			}
		}	
	}
	.small-legend {		
		margin: 20px;
		height: 100%;
		display: flex;
		flex-direction: column;
		.small-legend-text {
			margin: 20px 10px;
			font-weight: 700;
			font-size: 18px;
			div {
				font-size: 16px;
				font-weight: 400;
			}
		}			
	}
	.help-pdfs .pdf-card {
		.card-header {
			height: 110px;
		}
		.card-body {
			font-size: 40px;
		}
	}
	.help-pdf-title {
		font-weight: 700;
		font-size: 64px;
	}
	.help-pdf-sub-title {
		margin-top: 50px;
		font-size: 16px;
	}	
}

@media only screen and (max-width: 1400px) {
	.simulator {
		.decorator {
			font-size: 120px;
			line-height: 140px;
		}
		.mock-form-title {
			font-size: 40px;
		}
	}
}

@media only screen and (max-width: 1200px) {	
	.simulator {
		.decorator {
			font-size: 80px;
			line-height: 100px;
		}		
	}
}


@media only screen and (max-width: 992px) {
	.simulator {
		.mock-form-title {
			font-size: 24px;
		}			  	
	}			
}

@media only screen and (max-width: 768px) {
	.simulator {
		.mock-form-title {
			font-size: 22px;
		}
		.suggested-form .row .suggested-form-item {
			.form-control {
				padding-left: 5px;
				padding-right: 5px;
				font-size: 0.75em;
			}
			.col-form-label, .mock-form .row .mock-form-item .col-form-label {
				font-size: 18px;
				line-height: 26px;
				padding-left: 0;
			}
		} 
			}
}

@media only screen and (max-width: 576px) {	
	.simulator {
		.suggested-form .row .suggested-form-item .no-padding {
				padding: 0;		
		}
		.mock-form-title {
			font-size: 20px;
		} 		
		.row .mock-form-item {
			padding: 0;
			.col-form-label.form-label {
				font-size: 20px;
				text-align: center;				
			}
			.muvr-select__value-container.css-319lph-ValueContainer {
				font-size: 12px;
			}
			.col-8,.col-1,.col-2 {
				padding-left: 2px;
				padding-right: 2px;
			}
		}
		.suggested-title .suggested-text {	
			text-align: center;
			margin-bottom: 30px;
		}
	}	
	
}

@media only screen and (max-width: 359px) {	
	.simulator {	
		.row .mock-form-item .muvr-select__value-container.css-319lph-ValueContainer {
				font-size: 10px;
		}
	}	
}

.light-mode .simulator {
	.sub-title {
		
		color: black;
		a {
			color: black;			
		}
	}	
}