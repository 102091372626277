.caption-col {
    text-align: center;
    .caption {
        .caption-title,.caption-subtitle {
            &.caption-title {
                text-align: center;
            }            
            font-weight: 700;
            font-size: 40px;
            line-height: 80px; 
        }
        .caption-text {
            margin-top: 20px;
            font-weight: 400;
            font-size: 18px;
            line-height: 26.1px;   
        }    
    }
    .btn {
        margin-top: 64px;
        margin-right: 24px;
        font-size: 18px;
        line-height: 21px;
        padding: 22px;        
    }
}
.category-title {
    margin-top: 30px;
    text-align: center;
    font-size: 48px;
    line-height: 60px;   
    margin-bottom: 160px;
}
.menu-holder {    
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;        
    .outer-ring,.wheel-holder {
        width: 360px;
        height: 360px;
        grid-column: 1;
        grid-row: 1;
        display: flex;
        align-items: center;
        justify-content: center;           
    }
    .outer-ring {
        margin-top: 8px;
    }
    .wheel-holder {                
        .wheel-menu {
            width: 300px;
            height: 300px;   
            border-radius: 50%; 
            border: 2px solid $body-bg; 
            position: relative;      
            background-color: $body-bg;          
            .step {
                width: 100%;
                padding-bottom: 100%;
                position: absolute;
                border-radius: 50%;  
                cursor: pointer;   
                &:hover {          
                    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.6);
                }        
                &.calculator {
                    background-color: #FAE4CD;        
                    clip-path: polygon(50% 50%, 50% 0%, 100% 0%);                        
                }
                &.profiler {
                    clip-path: polygon(50% 50%, 100% 0%, 100% 50%);
                    background-color: #AAA8F8;
                }
                &.dashboard {
                    clip-path: polygon(50% 50%, 100% 50%, 100% 100%);
                    background-color: #AFDBF3;
                }
                &.career-exp {
                    clip-path: polygon(50% 50%, 100% 100%, 50% 100%);   
                    background-color: #FAE3FF; 
                }
                &.campus-exp {
                    clip-path: polygon(50% 50%, 50% 100%, 0% 100%);   
                    background-color: #DBE2E2;; 
                }
                &.simulator {
                    clip-path: polygon(50% 50%, 0% 100%, 0% 50%);  
                    background-color: #B5EFDE; 
                }
                &.support {
                    clip-path: polygon(50% 50%, 0% 50%, 0% 0%);  
                    background-color: #FFD3DB;  
                }
                &.notification {
                    clip-path: polygon(50% 50%, 0% 0%, 50% 0%);   
                    background-color: #C1F0BC; 
                }
            }
            .logo-holder {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: $body-bg;
                clip-path: circle(59px at 50% 50%);       
                img {
                    width: 70px;      
                }    
                
            }    
            .wheel-overlay {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                pointer-events: none;
                img{
                    width: 100%;
                }

            }
        }
    }
}

@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    width:  $circle-size;
    height: $circle-size;
    padding: 0;
    border-radius: 50%; 
    list-style: none;       

    > * {
        display: block;
        position: absolute;
        top:  50%; 
        left: 50%;
        width:  $item-size;
        height: $item-size;       
        margin: -(calc($item-size / 2));
        $angle: calc(360 / $item-count);
        $rot: -67.5;

        @for $i from 1 through $item-count {
            &:nth-of-type(#{$i}) {
                transform: 
                rotate($rot * 1deg) 
                translate(calc($circle-size / 2))
                rotate($rot * -1deg);
            }
            $rot: $rot + $angle;
        }

    }
}

.circle-container {
    @include on-circle($item-count: 8, $circle-size: 360px, $item-size: 30px); 
    border: solid 5px #9747FF;  
    transition: 0.15s; 

    li { 
        display: block; 
        max-width: 100%; 
        border-radius: 50%;        
        border: solid 1px #16161E;
        background-color: white;
        transition: .15s;       
                
        &.complete {
            background-color: #A6FF7C;
            svg {                
                font-size: 1.6em;
                margin-top: 3px;
                color: #16161E;
            }
        }
    }
}


@media screen and (min-width: 768px) { 
    .caption-col .caption .caption-title {           
        font-size: 64px;
    }
    .menu-holder {
        .outer-ring {
            width: 640px;
            height: 640px; 
        }
        .wheel-holder {
            width: 640px;
            height: 640px;   
            .wheel-menu {
                width: 520px;
                height: 520px;
                .logo-holder {
                    clip-path: circle(70px at 50% 50%);
                    img{
                        width: 90px;
                    }
                }
            }           
        }
        .circle-container {
            @include on-circle($item-count: 8, $circle-size: 640px, $item-size: 40px); 
            li.complete svg {
                font-size: 2em;
                margin-top: 5px;
            }
        }
    }
}

@media screen and (min-width: 1200px) { 
    .menu-holder {
        .outer-ring {
            width: 800px;
            height: 800px; 
        }
        .wheel-holder {
            width: 800px;
            height: 800px;   
            .wheel-menu {
                width: 650px;
                height: 650px;
                .logo-holder {
                    clip-path: circle(80px at 50% 50%);
                    img{
                        width: 120px;
                    }
                }
            }    

        }
        .circle-container {
            @include on-circle($item-count: 8, $circle-size: 800px, $item-size: 50px); 
            li.complete svg {
                font-size: 2.5em;
                margin-top: 5px;
            }
        }
    }
}