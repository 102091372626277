.campus-tours,.career-exp {		
	.banner-group {
		text-align: center;
		.banner-lg {
			text-align: left;
			font-weight: 700;
			font-size: 12vw;
			background: linear-gradient(89.62deg, rgba(144, 218, 196, 0.6) 1.53%, rgba(154, 119, 248, 0.6) 94.59%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
		}
		.banner-inset {
			text-align: left;
			position: relative;			
			font-weight: 700;
			font-size: 4vw;
			line-height: 12vw;
			color: #FFFFFF;
			left: 68px;
			top: -14vw;
		}
	}
	.sub-banner {
		font-weight: 700;
		font-size: 4vw;
	}
	.lib-description {
		width: 440px;
		margin-top: 40px;
	}	
	.carousel {
		.carousel-item {
			background: #1C1C25;
			border-radius: 16px;			
			.legend {
				position: absolute;
				right: 20px;
				top: 20px;	
			}
			.tours {				
				padding: 50px;
				.card {					
					cursor: pointer;
					margin: 20px;
					border-radius: 8px;
					height: 90%;
					.img-holder {							
						display: flex;
						margin: auto;			
						img {
							max-width: 100%;
							height: auto;
							align-self: center;	
						}
					}
					.card-body {
						padding: 10px;
					}
					.card-text {
						font-weight: 700;
						font-size: 2vh;
						color: #1C1C25;
					}
					.card-footer {
						border-top: none;
						background-color: unset;
					}
				}
			}
		}		
		.carousel-control-prev {
			justify-content: flex-start;
			padding-left: 20px;
			width: 5%;
		}
		.carousel-control-next {
			justify-content: flex-end;
			padding-right: 20px;
			width: 5%;
		}
	}
}