
.muvr-content {		
	.calculator-form {	
		.row {
			margin: 0;
		}	
		.season-btn {
			width: 100%;
			height: 80px;
			margin-bottom: 20px;
		}
		.form-row-container {			
			border-radius: 10px;
			padding: 20px 10px;
			margin: 10px 0;			
			.points {
				border-radius: 10px;				
				height: 42px;			
				font-weight: 400;
				font-size: 20px;
				line-height: 24px;
				text-align: center;
				color: #45454B;
				display: flex;
				align-content: center;
				align-items: center;
				justify-content: center;
			}	
			&:nth-child(2) {
				background-color: #AFDBF3;
			}
			&:nth-child(3) {
				background-color: #CCCBF0;
			}
			&:nth-child(4) {
				background-color: #FAE4CD;
			}
			&:nth-child(5) {
				background-color: #C1F0BC;
			}
			&:nth-child(6) {
				background-color: #FFD3DB;
			}
			&:nth-child(7) {
				background-color: #B5EFDE;
			}
			&:nth-child(8) {
				background-color: #DBE2E2;
			}
			&:nth-child(9) {
				background-color: #FAE3FF;
			}
		}
		.reset-btn {
			margin-top: 20px;
		}
		.total-points {
			font-weight: 400;
			font-size: 24px;			
			margin-top: 35px;
			.tp-label {
				background: linear-gradient(89.62deg, #90DAC4 1.53%, #9A77F8 94.59%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
				margin-right: 20px;	
			}
			@media screen and (max-width: 576px) { 
				font-size: 18px;
			}
		}
	}
	.lg-title {		
		font-weight: 700;
		font-size: 130px;
		@media screen and (max-width: 992px) { 
			font-size: 100px;	  		
		}
		@media screen and (max-width: 768px) { 
			font-size: 70px;	  		
		}
		@media screen and (max-width: 576px) { 
			font-size: 40px;	  		
		}
	}
	.point-bubble {		
		background: linear-gradient(180deg, #90D9C6 0%, #9747FF 100%);
		border-radius: 50%;
		width: 240px;
		height: 240px;			
		padding-top: 90px;		
		.points {
			margin-top: 20px;
			font-weight: 400;
			font-size: 24px;			
		}
		@media screen and (max-width: 1200px) { 
			width: 180px;
			height: 180px;
			padding-top: 50px;
			.season {
				font-size: 14px;
				padding: 0 10px;	
			}
			.points {
				margin-top: 20px;				
				font-size: 18px;			
			}		
		}
		@media screen and (max-width: 992px) { 
			width: 150px;
			height: 150px;
			padding-top: 38px;
			.season {				
				padding: 0 10px;				
			}
			.points {								
				font-size: 18px;			
			}		
		}
		@media screen and (max-width: 768px) { 
			width: 100px;
			height: 100px;
			padding-top: 15px;
			.season {
				display: none;
			}
			.points {						
				font-size: 16px;			
			}		
		}
		@media screen and (max-width: 576px) { 
			width: 80px;
			height: 80px;
			padding-top: 12px;
			.season {
				display: none
			}
			.points {						
				font-size: 12px;			
			}
		}
	}
	.avg,.chart-title,.next-step {
		&.avg span {
			margin-right: 15px;
		}		
		&.chart-title {
			text-align: center;
			margin-bottom: 30px;
		}
		font-size: 28px;    
		&.next-step .btn{
			margin-top: 20px;
			padding: 15px;
		}    
	}	
	.lowest-points {
		margin-top: 30px;
		padding: 0 50px;
		
	}	
	.study-tips {		
		a {
			text-align: center;
			font-weight: 700;
			font-size: 20px;			
			text-decoration-line: underline;
			color: unset;
		}		
	}
	.recharts-surface {
		overflow: visible;
		padding: 10px;
	}
	.title .no-user {
		margin-top: 40px;				
	}
	.login-bottom .btn {
		margin-top: 30px;
	}
}