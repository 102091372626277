.dashboard {
	.main-title {
		font-weight: 700;
		font-size: 64px;
		line-height: 125%;
	}
	.background-gradient {
		background: linear-gradient(180deg, #90DAC4 0%, rgba(217, 217, 217, 0) 79.17%);
		height: 300px;		
		position: absolute;	
		z-index: -1
	}
	.no-step {
		font-size: 20px;
		margin: 100px 0;
		.btn {
			margin-top: 20px;
			font-size: 18px;
			line-height: 18px;
			padding: 22px;
		}	
	}
	.careers {		
		.dash-filters{
			width: 100%;
			display: flex;
			flex-direction: row-reverse;
			padding: 40px 80px 0 80px;
			.dash-filter {
				width: 280px;					
			}
			.muvr-select__multi-value {
				background-color: #7157F8;
				.muvr-select__multi-value__label {
					color: white;
				}				
			}
		}
	}	
	.career-card,.course-card {
		color: #1C1C25;		
	}
	.spinner-border {
		color: #7157F8;
	}
	.nav-pills {
		.nav-item {	
			margin: 2px 4px;				
			a {
				background-color: unset;
				color: #7157F8;	
				border: 1px solid #7157F8;			
				&.active, &:hover {
					background-color: #7157F8;
					color: white;		
				}
				&.disabled {					
					color: #6c757d;	
					border-color: #6c757d;		
				}
			}
		}
	}	
	.tab-content {
    	padding: 15px 0 30px 0;
		.item-title {
			font-weight: 700;
			font-size: 30px;
			text-align: center;
		}
		.item-subtitle {
			font-size: 18px;
		}
       	.card {	
       		text-align: left;     		
			.card-footer,.card-header {
				border: none;
				background: unset;
				&.card-footer {
					color: white;	
				}	
			}					
       	}           
		.career {			
			.card {					
				height: unset;					
				background: #AFDBF3;	
				padding: 15px;		
				text-align: center;
				.card-header {
					img {
						width: 308px;
						height: 206px;
					}	
				}
				.card-subtitle, .card-text {						
					font-size: 20px;	
					&.card-subtitle {
						font-weight: 700;
					}
					&.card-text {
						font-weight: 400;
						min-height: 39px;
					}
				}
				.career-desc {
					margin-bottom: 60px;
				}
				.card-title {
					margin: 0 auto;
					font-size: 32px;
					font-weight: 700;
					text-align: center;
					margin-bottom: 30px;
				}	
				.card-footer {
					display: flex;
					justify-content: space-around;
					.btn {
						margin: 5px;
					}
				}						
				@media screen and (min-width: 768px) {
					width: 680px;	
				}
				@media screen and (min-width: 992px) {
					width: 900px;
				}
			}					
		}	
		.courses-tab {	
			display: flex;
	        flex-direction: column;
	        align-items: center;	        
	        .card-holder-title {
	        	font-size: 32px;
	        }
	        .page-btn-holder {
	        	position: relative;
	        	margin-top: 10px;	        	
	        	width: 50px;
				display: flex;
			    align-items: center;			    
			    &.left {
			    	justify-content: flex-end;
			    }
			    &.right {
			    	justify-content: flex-start;
			    }
	        	.page-btn {
					cursor: pointer;
					font-weight: bold;
					font-size: 40px;					
				}	
	        }			
			.card-holder {
				display: flex;	
				justify-content: space-around;
				.course-card {		
		       		margin: 20px;		       		
		       		min-height: 650px;
		       		width: 270px;	
		       		.card-header {
						font-size: 2rem;
						display: flex;
						justify-content: space-between;
						flex-direction: row-reverse;
						svg{
							cursor: pointer;
							&.fill {
								color: yellow;
							}
							&:hover {
								color: white;
							}
						}
					}				
		       		&.short-card {
		       			min-height: 550px;
		       		}	
		       		.card-header .star-label {
		       			position: absolute;
						font-size: 14px;
						width: 160px;
						left: 60px;
		       		}	       		
					&.realistic {
						background-color: #AFDBF3;
					}
					&.safety {
						background-color: #C1F0BC;
					}
					&.aspirational {
						background-color: #FAE4CD;
					}
					.card-subtitle, .card-text {						
						font-size: 16px;								
						&.card-subtitle {
							font-weight: 700;
						}
						&.card-text {
							font-weight: 400;
							margin-bottom: 10px;								
						}													
					}
					.img-holder {
						width: 100%;
						height: unset;
						display: flex;
			            flex-direction: column;
			            align-items: center;   
			            margin-bottom: 30px;
						.card-img-top {
							height: 150px;
							margin: 0 auto;
						}
					}
					.card-footer {
						display: flex;
						justify-content: space-evenly;	
						gap: 5px;						
					}	
					.card-footer {
						text-align: center;
					}					
				}		
			}	
			@media screen and (max-width: 420px) {
				.card-holder {
					width: 350px;
					margin: 0;
					.course-card {					       		
			       		width: 250px;				       		
			       		.card-header .star-label {			   
							left: 15px;
			       		}	      		
					}		
				}			
			}
			@media screen and (max-width: 420px) {

			}
		}		
	}
	.no-courses {
		height: 500px;
		display: flex;
		flex-direction: column;
		justify-items: center;
		justify-content: center;
		font-size: 20px;
	}
	
}
#req-popover {
	color: black;
	background-color: #CCCBF0;
	.popover-body {
		color: black;	
	}
	.popover-arrow::after {
		border-top-color: #CCCBF0;
	}
	.req {
		margin: 5px;
	}
}
.carousel-label {
	margin-top: 20px;
	font-size: 18px;
	padding: 0 20px;
	@media screen and (min-width: 768px) {
		padding: 0 100px;
	}
	@media screen and (min-width: 992px) {
		padding: 0 300px;
	}
}
.pagination {
	.page-item {
		.page-link {
			color: #7157F8;
			background-color: #45454B;
			border-color: $body-bg;			
		}
		&.active .page-link {
			background-color: #7157F8;
			color: white;
		}
	}
}