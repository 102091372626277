@import "~bootstrap/scss/functions";


$body-bg: #16161E;
$body-color: white;

$theme-colors: (
  "primary": #7157F8,  
  "secondary": #9747FF,
  "info": #C1F0BC,
  "grey-dark": #45454B
);

@import "./modal.scss";
@import "./form.scss";
@import "./navbar.scss";
@import "./home.scss";
@import "./calculator.scss";
@import "./button.scss";
@import "./interest-profiler.scss";
@import "./simulator.scss";
@import "./dashboard.scss";
@import "./notifications.scss";
@import "./campus-tours.scss";
@import "./support.scss";
@import "./about-us.scss";
@import "./contact-us.scss";

body {   
    .muvr-content {
        width: 100%;        
        .muvr-row {
            margin-top: 80px;
            margin-bottom: 120px;
            &.no-margin {
                margin-top: 0;
                margin-bottom: 0;
            }            
            &.title-row {
                margin-bottom: 0;    
            }    
            &.titled-row {
                margin-top: 30px;
            }           
        }        
        .title {                   
            .main-title {               
                font-size: 56px;
                line-height: 66px;
                margin-bottom: 20px;                  
            }                     
        }
    }
    .footer {
        text-align: center;
        font-size: 16px;
        line-height: 23.2px;  
        border-top: 1px solid map-get($theme-colors,"grey-dark");
        padding: 20px;    
        margin: 40px 60px 0 60px;
        .social-icons {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 10px;
            margin-top: 10px;
            cursor: pointer;
            img {
                height: 25px;
                width:25px;
            }
        }
        .copyright {
            margin-top: 15px;
        }
        .footer-links {
            display: flex;
            justify-content: center;
            gap: 30px;
            a {
                color: white;
                text-decoration: none;
                font-size: 18px;
            }
        }
    }    
    &.light-mode {   
        background-color: #EEEEEE;
        color: black;
        .backdrop {
            background-color: #E8E8E8;
        }
    }   
    .gradient-text {        
        background: linear-gradient(89.62deg, #90DAC4 1.53%, #9A77F8 94.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .basic-single {  
        text-align: left;
        .muvr-select__control {
            background-color: $body-bg;
            border-color: $body-bg;
            border-radius: 10px;           
            height: 42px;

        }
        .muvr-select__menu {
            background-color: $body-bg;
            color: white;
        }
        .css-qc6sy-singleValue {
            color: white;
        }   
        .muvr-select__indicators {
            .muvr-select__indicator-separator {
                display: none;
            }
        }
        .muvr-select__single-value--is-disabled {                   
                color: white;                   
        }
    }.btn-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
    }     
    img.screencap {
        width: 100%;
        margin-bottom: 200px;
    }   
    .anchor-btn {                       
        cursor: pointer;
        text-decoration: underline;
    }      
    .img-holder {
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
        }
    }   
    .vids-row .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;    
        margin: 40px 0;             
    }
    .centered-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;               
    }   
    .help-vid, {
        background: #45454B;
        border-radius: 10px;    
        padding: 20px;
        width: fit-content;
    }      
    .register {
        @media screen and (max-width: 991px) {
            margin-top: 40px;
        }
    }
    .step-link {
        font-size: 24px;
        .btn {
            margin-top: 30px;
            line-height: 42px;
            width: fit-content;
        }
    }
    .banner-group {
        .banner-lg {
            font-weight: 700;
            font-size: 8vw;
            background: linear-gradient(89.62deg, rgba(144, 218, 196, 0.6) 1.53%, rgba(154, 119, 248, 0.6) 94.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
        .banner-inset {
            text-align: left;
            position: relative;         
            font-weight: 700;
            font-size: 4vw;
            color: #FFFFFF;
            left: 68px;
            top: -8vw;
        }
    }
    .help-pdfs {
        background: #1C1C25;
        border-radius: 16px;
        padding: 50px;
        margin-top: 50px;
        .pdf-card {
            color: #1C1C25;
            margin: 10px 0;
            width: 220px;
            height: 220px;
            .card-header {
                font-weight: 700;               
                border-bottom: none;
                background: unset;
                display: list-item;
                list-style:none;
                font-size: 21px;
            }
            .card-body {
                img {
                    width: 100%;
                    height: auto;       
                    margin: 0 auto;         
                }
                padding: 0 20px;
            }
            .card-footer {
                border-top: none;
                background: unset;
                text-align: left;
                a {
                    font-weight: 700;                   
                    text-decoration: none;
                    color: #1C1C25;
                }
            }
        }
    }
}

@import "~bootstrap/scss/bootstrap";