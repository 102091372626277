.support {
	.top-row {
		margin-top: 80px;		
	}	
	.caption-row {		
		.caption {
			margin-top: 70px;
		}
		.img-holder {
			height: 100%;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 100%;
			}
		}
	}
	.title {
		font-weight: 700;
		font-size: 64px;
	}
	.sub-banner {
		font-weight: 700;
		font-size: 56px;
	}
	.lib-description {
		margin-top: 40px;
	}
	.sub-title {
		margin-top: 50px
	}	
	.help-pdfs .pdf-card .card-header {
		height: 70px;
	}
}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 992px) {

}

@media screen and (min-width: 1200px) {

}

.vids-title {
	font-size: 28px;
	margin: 60px 0 30px 0;
}