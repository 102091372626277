.about-us {
	margin-top: 20px;
	text-align: center;
	.row {
		margin: 60px 0;
		.about-title {
			font-weight: 700;
			font-size: 20px;
		}
		.about-text {
			text-align: justify;
		}
		.about-contact {
			text-align: center;
			margin-bottom: 30px;			
		}
		.about-tests {
			margin-top: 45px;
			.tests-title {
				font-weight: 700;
				font-size: 20px;
			}
			.test {		
				margin: 45px;		
				.test-text {

				}
				.test-by {
					margin-top: 15px;
					font-weight: 500;
				}
			}
		}	
		img {
			border-radius: 5px;
		}
	}
	
}