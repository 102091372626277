.interest-profiler {
	.realistic {
		background-color: #AFDBF3;
	}
	.investigative {
		background-color: #CCCBF0;	
	}
	.artistic {
		background-color: #FAE4CD;	
	}
	.social {
		background-color: #C1F0BC;	
	}
	.enterprising {
		background-color: #FFD3DB;	
	}
	.conventional {
		background-color: #B5EFDE;	
	}
	.btn.fill-width {
		width: auto;
	}
	.questions{
		margin-bottom: 40px;
		width: 100%;	
		.card {
			margin: auto;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding: 15px;	
			min-height: 490px;			
		}
		img {
			width: fit-content;
		}
		.card-text {
			margin-top: 15px;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: $body-bg;
		}	
		.card-footer {
			display: flex;
			flex-direction: column;		
            border-top: none;
            background-color: unset;
            justify-content: space-between;
			padding: 5px;
			gap: 10px;
			.answer {
				display: flex;
				flex-direction: row;
				.answer-label {					
					font-weight: 700;
					font-size: 11px;					
					display: flex;					        
	                align-items: center;
	                padding-left: 10px;
				}
	            .btn {
					width: 35px;				
					border-radius: 45px;
					border-width: 2px;			
					height: 35px;
					padding: 0;		
					font-weight: 700;
					font-size: 20px;
					line-height: 24px;	
				}
				&:hover {
					background-color: unset;
					filter: brightness(150%);
				}					
				
			}	
		}		
		.banner {
			font-weight: 700;
			font-size: 56px;
			line-height: 67px;	
			margin-bottom: 40px;
		}		
	}
	.best-cat-title {
		font-weight: 400;
		font-size: 30px;
		line-height: 150%;
	}
	.best-cat-card {
		color: black;
		text-align: left;
		height: 95%;
		margin-bottom: 12px;
		.title {
			margin-top: 10px;
			font-weight: 700;
			font-size: 30px;
			line-height: 150%;
		}
		.description {
			margin-top: 10px;
			font-weight: 400;
			font-size: 20px;
			line-height: 150%;
		}
	}
	.profile-code {
		font-weight: 400;
		font-size: 36.0598px;
		line-height: 43px;		
	}
	.chart-holder {
		height: 800px;
		.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis .recharts-text.recharts-cartesian-axis-tick-value {
			fill: white;
			font-size: 18px;
		}
	}	
    .link-btn {
    	display: flex;
		align-items: center;
    }  
    .career-list {
    	background-color: #9A77F8;
    	margin-bottom: 100px;
    	border-radius: 20px;
    	padding: 10px;
    	.career-title {
    		font-weight: 700;
			font-size: 56px;
			line-height: 67px;
			background: linear-gradient(89.62deg, #90DAC4 1.53%, #FAE4CD 94.59%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
    	}
    	.category-name {
    		font-weight: 700;
			font-size: 32px;
			line-height: 38px;
			font-weight: 700;
			color: #45454B;
    	}
    	.career-subtext {
			font-size: 20px;
			line-height: 24px;
			padding: 20px 0;
    	}
    	.career-form {
    		background-color: #D9D9D9;
    		border-radius: 10px;
    		margin: 5px 30px;
    		.row {
    			margin: 0;    			
    		}
    		.form-item {
    			font-weight: 700;
				font-size: 32px;
				margin: 0 10px;
				color: #1C1C25;				
				&:hover {
					color: white;
				}
				.form-check-label, .form-check-input {
					cursor: pointer;
				}
				@media screen and (max-width: 1199px) { 
					font-size: 20px;
				}
				@media screen and (max-width: 991px) { 
					font-size: 18px;
				}
				@media screen and (max-width: 767px) { 
					font-size: 16px;
				}
				@media screen and (max-width: 575px) { 
					font-size: 14px;
				}
    		}
    	}
    	.link-holder {
    		height: 30px;
    		padding-right: 30px;
	    	a {
	    		color: white;
	    		font-weight: 700;
				font-size: 20px;				
				text-align: center;
				text-decoration-line: underline;
				float: right;
	    	}
	    }
    }
}

.light-mode .interest-profiler .chart-holder .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis .recharts-text.recharts-cartesian-axis-tick-value {
	fill: black;
} 