
.header {
    margin: 0 30px;
    .dropdown-toggle::after {    
        display: none;
    }  
    .navbar-brand img {
        @media screen and (max-width: 420px) {
            width: 100px;
        }
    }
    .dropdown-menu.show { 
        position: absolute;   
        top: 180px;        
        transform: translate(-50%, -50%);        
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;            
        background: #1C1C25;
        border-radius: 10px;
        padding: 20px 180px;
        gap: 30px 80px;       
        .dropdown-item {
            grid-column: span 2;
            padding: 0;
            &.menu-title {
                grid-column: span 6;
                position: relative;
                right: 19px;
            }
            &.last-row {
                grid-column: span 3;
            }
            text-align: center;     
            background: conic-gradient(from 180deg at 50% 50%, #90D9C6 0deg, #9747FF 360deg);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;           
            text-decoration: underline white;
            text-underline-position: under; 
            font-size: 20px;   
        }  
        
        @media screen and (max-width: 757px) {                
            .dropdown-item {
               font-size: 16px;
            }              
        }     
        @media screen and (max-width: 575px) { 
            display: block;
            .dropdown-item {
                margin: 20px auto;
                &.menu-title {      
                    right: 0;
                }
                font-size: 20px;
            }              
        }       
    }  
    .event-cal {    
        margin-right: 20px;           
    }
    .close-btn {                        
            position: absolute;
            float: right;
            right: 20px;
            top: 10px;   
            padding: 0;
            border: none;  
            background: none;         
            svg {  
                rotate: 90deg;                
            }
        }  
    #toggle-collapse {
        width: 100%;
    }    
    .navbar-nav {
        margin-left: auto;
        gap: 50px;
        .col {
            width: 220px;            
        }
    }
    .nav-link {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        text-align: center;
        background: conic-gradient(from 180deg at 50% 50%, #90D9C6 0deg, #9747FF 360deg);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        @media screen and (max-width: 575px) {
            font-size: 16px;
            padding: 5px 7px;
        }
    }
    .theme-switch {
        float: right;
    }
    .nav-group {     
        text-align: right;  
        .top-row { 
            display: flex;
            .bell-btn {
                color: white;
            }
            .user-icon {
                background-color: #AFDBF3;
                color: #9747FF;
                border-radius: 45px;           
                width: 48px;
                height: 48px;
                display: flex;
                align-items:center;
                justify-content:center;
                font-size: 2em;
                margin-right: 10px;
            }
            .navbar-text {
                margin-left: 10px;
                margin-right: 20px;
                color: #9747FF;
                font-size: 18px;
                line-height: 21px;
            }
            .user-menu {
                display: flex;
                align-items: center;    
            }
        }
        .theme-switch {
            margin-top: 15px;    
        }
        .dropdown-menu {
            background: #1C1C25;    
            &.show{
                padding: 20px 40px;
            }
            a {
                background: linear-gradient(89.62deg, #90DAC4 1.53%, #9A77F8 94.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }
    .navbar-toggler {
        position: absolute;
        left: 125px;     
    }
    .sub-nav {
        justify-content: center;
        .dropdown-menu.show {
            width: fit-content;
            left: 0;
            padding: 20px 30px;
            gap: 30px 10px;
            overflow: hidden;
            font-size: 16px;                
            @media screen and (max-width: 575px) { 
                width: 370px;
                //left: -62px;
                transform: translate(-280px, -150px)
            }               
        }
    }
    .nav-lg {
        .col {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;   
            .nav-link {
                width: max-content;            
            }
        }       
    }   
}