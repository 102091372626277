form {
	.form-control {
        background-color: white !important;
        color: black !important;        
    }
}
.form-holder {  
    height: 1400px; 
    form {        
        .form-control { 
            // width: 450px;
        }                 
        &.muvr-form {
            margin: 0 auto;
            border-radius: 7px;
            background: linear-gradient(180deg, #9A77F8 0%, #AFDBF3 100%);
            color: #16161E;   
            padding: 50px;       
            // width: 550px; 
            text-align: left;
            .muvr-form-body {
                min-height: 600px;
                .form-error {
                    min-height: 100px
                }
                .terms {
                    // width: 450px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px; 
                    text-align: left;           
                    .terms-title {
                        text-align: center;
                    }
                    div {
                        margin-top: 15px;
                        &.form-check {
                            margin-left: 20px;
                        }
                        &.terms-or {
                            margin-left: 40px;
                        }
                    }            
                    a {
                        color: $body-bg;
                    }          
                } 
                .form-title {
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 43px;
                    text-align: center;
                    color: #B3B3B3;
                    margin: 20px 0 40px 0;
                    
                }
                .form-text {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                }
                .form-label {          
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 10px;
                }   
            } 
        }         
        .form-actions {
            margin: 40px 0 20px 0;
            background-color: transparent;
            text-align: center;
            .btn {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px; 
                width: fit-content;                          
            }
        }        
    }
    &.decorator {
        padding-bottom: 100px !important;
    }
    .form-decorator {
        position: absolute;
        bottom: -380px;
        margin-left: -240px;    
        pointer-events: none;    
        svg,img {
            position: absolute;
            &.upper-circle {
                  margin-top: 120px;
                  margin-left: 140px;                        
            }
            &.lower-circle {
                margin-top: 340px;
                margin-left: 80px;             
            }
            &.half-ring {
                margin-top: 342px;
                margin-left: 420px;                          
            }
            &.line {
                margin-top: 230px;
                margin-left: 10px;          
            }
            &.books {
                          
            }
        }
    }        
}
.theme-switch {  
    .react-switch-bg {        
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .react-switch-handle {
        text-align: center;
        background: linear-gradient(315.45deg, #FFFFFF 15.4%, rgba(255, 255, 255, 0) 85.7%) !important;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
    }
    
}
