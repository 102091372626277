.modal {
    .modal-content {
        background: linear-gradient(180deg, #7157F8 0%, #AFDAF3 100%);
        border-radius: 10px;  
        border: none;
        .modal-header {                   
            border: none;
            flex-direction: column;   
            .close-btn-holder {                    
                width: 100%;
                .btn-close {
                    background: none;
                    float: right;
                    min-width: unset;
                    width: fit-content;
                    height: fit-content;
                }     
            }            
            .modal-title {
                font-weight: 700;
                font-size: 128px;
                text-align: center;
                width: 100%;         
                &.terms-title {                   
                    font-size: 36px;
                    color: #B3B3B3;
                }    
                &.gs-title,&.hiw-title {
                    font-size: 40px;    
                }    
            }                   
        }
        .modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;                
            form {   
                .form-label {                
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    text-align: center;                    
                }
                .form-control {
                    width: 327px;
                }                   
            }
            a {
                color: white;
            }
            .terms-text {
                height: 550px; 
                overflow: auto;
                font-weight: 700;
                margin: 0 30px;
            }
            .no-lst {
                list-style-type: none;
            }
        }
        .modal-footer {
            border: none;
            justify-content: center;
            padding-bottom: 20px;
            .btn-info {
                color: map-get($theme-colors,"grey-dark");                   
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
            }
        }           
    }  
    .btn {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        width: fit-content;  
    }
    &.events-modal {
        .modal-content .modal-header .modal-title {
            font-size: 40px;   
        }
        .event-card {
            color: black;  
            background-color: #C1F0BC; 
            width: 320px;
            margin: 20px;     
            cursor: pointer;  
            height: 450px;   
            .card-header {
                border-bottom: none;
                background-color: unset;
                .img-holder {
                    height: auto;
                    img {
                        width: auto;
                        height: 120px;
                    }
                }
            }
            .card-body { 
                text-align: left;
                .card-subtitle, .card-text {                        
                    font-size: 16px;    
                    &.card-subtitle {
                        font-weight: 700;
                    }
                    &.card-text {
                        font-weight: 400;
                        min-height: 39px;
                    }                           
                }
            }
            .card-footer {
                display: flex;
                justify-content: space-evenly;                
                border: none;
                background: unset;               
                .btn {     
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 29px;
                }
            }
        }
    }
    .login-error {
        color: red;
        text-align: center;
        font-size: 18px;
    }
}